<template>
    <svg width="100%"
         xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 1261.9 1697"
         :aria-label="props.title">
        <title>{{ title }}</title>
        <path :fill="props.color"
              d="M0,1038.2c3.5,261.9,191.3,484.9,424.1,584.9c-142.7-93-241.9-261.5-215.4-435.5
	c20.1-132.2,117.4-211.8,215.5-290.5c79.5-63.7,139.9-132.2,128.9-240.1c-1.9-18.3-14-123.3-39-131c39.9,12.2,83.8,31.9,121.5,49.8
	c174.7,83.1,330.8,256.3,387.3,441.6c68.5,225,46.1,483.6-166.6,621.4c-17,11-35,20.7-53.6,28.8c89.5-18.5,175.3-59.7,250.4-128.1
	c276.8-252.3,247-634.6,92.7-946.2c-127-256.5-396.5-471.2-671.3-550.1c-59.2-17-127.9-35.2-189-43.2c38.2,5,81.3,155.2,88.4,181.5
	c42.2,154.6-29.4,269-129.8,381.1C120.1,701-2.5,840.5,0,1038.2z" />
        <path :fill="props.color"
              d="M735.5,1675.6c142.8-67,178.4-224.5,155.2-369.1c-19.2-119-100.8-239-201.5-304.9c-21.7-14.2-47.1-30.1-70.7-41
	c14.8,6.8,13.3,72.6,13,84c-2.3,67.5-45.3,104.6-99.7,137.2c-67.1,40.2-133.8,81.1-157.4,160.9c-42.9,145.4,63.4,304.6,200.6,344.7
	c24.1,7.2,49.3,10.3,74.5,9.4C679.3,1695.6,708.5,1688.4,735.5,1675.6z" />
    </svg>
</template>

<script setup lang="ts">
    import { getTrans } from '@/js/helpers/getTrans';

    const props = defineProps({
        color: {
            type: String,
            default: '#24013c',
            required: false,
        },
        title: {
            type: String,
            default: getTrans('quests-dashboard.connectToStreak', 'Connect to Streak'),
            required: false,
        },
    });
</script>
