<template>
    <div class="download-app-content">
        <template v-if="!isMobileDevice()">
            <div class="download-app-content__qr-wrapper">
                <div class="download-app-content__label">
                    <Translate translationKey="quests-dashboard.scanToDownload"
                               fallback="Scan to Download" />
                </div>
                <a :href="CRYPTO_REDIRECT_APP_URL"
                   rel="nofollow noopener noindex"
                   target="_blank">
                    <img loading="lazy"
                         :src="asset_cdn('assets/missions/app/bitdegree-app-qr.svg')"
                         class="download-app-content__qr-image"
                         width="150"
                         alt="Download Mobile App"
                         title="Download Mobile App">
                </a>
            </div>
            <div class="download-app-content__label download-app-content__label--uppercase">
                <Translate translationKey="quests-dashboard.or"
                           fallback="or" />
            </div>
        </template>
        <div class="download-app-content__app-wrapper">
            <div v-if="isAndroidDevice || !isMobileDevice()"
                 class="download-app-button">
                <a :href="ANDROID_MOBILE_APP_STORE_URL"
                   rel="nofollow noopener noindex"
                   target="_blank">
                    <img loading="lazy"
                         :src="asset_cdn('assets/missions/app/bitdegree-app-playstore.png')"
                         class="download-app-button__image"
                         width="200"
                         alt="Download Android Mobile App"
                         title="Download Android Mobile App">
                </a>
            </div>
            <div v-if="isIOSDevice || !isMobileDevice()"
                 class="download-app-button">
                <a :href="IOS_MOBILE_APP_STORE_URL"
                   rel="nofollow noopener noindex"
                   target="_blank">
                    <img loading="lazy"
                         :src="asset_cdn('assets/missions/app/bitdegree-app-appstore.png')"
                         class=" download-app-button__image"
                         width="200"
                         alt="Download iOS Mobile App"
                         title="Download iOS Mobile App">
                </a>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { asset_cdn, route } from '@/js/helpers/helpers';
    import { isIOS, isMobileDevice } from '@/js/auth/helpers/deviceUtils';

    const CRYPTO_REDIRECT_APP_URL = route('crypto.mobile-app.redirect');
    const IOS_MOBILE_APP_STORE_URL = route('crypto.mobile-app.ios-redirect');
    const ANDROID_MOBILE_APP_STORE_URL = route('crypto.mobile-app.android-redirect');

    const isAndroidDevice = ref(false);
    const isIOSDevice = ref(false);

    onMounted(() => {
        isAndroidDevice.value = isMobileDevice() && !isIOS();
        isIOSDevice.value = isIOS();
    });
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/breakpoints";
@import "@/sass/partials/mobile-app/download-app-buttons.scss";

.download-app-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;

    &__qr-wrapper {
        padding: 10px;
        border: 1px solid $gray300;
        border-radius: 16px;
        text-align: center;
    }

    &__qr-image {
        width: 150px;
        height: auto;
    }

    &__label {
        margin-bottom: 5px;
        line-height: 1.2;

        &--uppercase {
            text-transform: uppercase;
        }
    }

    &__app-wrapper {
        display: flex;
        gap: 16px;

        @media (max-width: $md-breakpoint-max-width) {
            flex-direction: column;
        }
    }

    .download-app-button {
        margin: 0;
    }

    @media (max-width: $sm-breakpoint-max-width) {
        :deep(.download-app-button__image) {
            max-width: 200px;
        }
    }
}
</style>
